"use client";

import { clsx } from "clsx";
import { usePathname } from "next/navigation";
import { OutlineChevronRightIcon } from "@/components/Icons/components/HeroIcons/OutlineChevronRightIcon.component";
import { archivePageLink } from "@/components/page-links.constants";
import { Translation } from "@/components/Translation/Translation.component";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import { useUser } from "@/contexts/user/user.context";
import { AccountButton } from "../Account/AccountButton.component";
import { MenuItem } from "../MenuItem/MenuItem.component";
import { ToolbarMenuItem } from "./components/ToolbarMenuItem.component";
type ToolbarMenuProps = {
  readonly className?: string;
  readonly theme?: "white" | "primary";
};
export function ToolbarMenu({
  className,
  theme = "primary"
}: ToolbarMenuProps) {
  const pathname = usePathname();
  const isArchivePage = pathname === archivePageLink;
  const {
    isLoggedIn,
    urls: {
      loginAndReturnUrl
    }
  } = useUser();
  const siteConfiguration = useSiteConfiguration();
  const hideLogin = Boolean(siteConfiguration.hideLogin);
  return <ul className={clsx("flex h-full items-center justify-end", className)} data-sentry-component="ToolbarMenu" data-sentry-source-file="ToolbarMenu.component.tsx">
      <ToolbarMenuItem className={clsx("transition-all", theme === "primary" && "font-theme-bold hover:text-primary", theme === "white" && "text-inherit after:absolute after:bottom-0 after:left-1/2 after:block after:h-0.5 after:w-0 after:-translate-x-1/2 after:bg-secondary after:transition-all after:content-[''] hover:text-secondary", isArchivePage && theme === "primary" && "text-primary", isArchivePage && theme === "white" && "font-theme-bold text-white after:!w-full after:bg-white hover:after:bg-secondary")} data-sentry-element="ToolbarMenuItem" data-sentry-source-file="ToolbarMenu.component.tsx">
        <MenuItem className="h-full w-full px-1 text-inherit" position="nav-search" targetUrl={archivePageLink} data-sentry-element="MenuItem" data-sentry-source-file="ToolbarMenu.component.tsx">
          <Translation da="Søg" de="Suchen" en="Search" no="Søk" sv="Sök" data-sentry-element="Translation" data-sentry-source-file="ToolbarMenu.component.tsx" />
        </MenuItem>
      </ToolbarMenuItem>
      {!hideLogin ? <ToolbarMenuItem className="pl-1.25">
          {isLoggedIn ? <AccountButton /> :
      // This should not be a <NextLink /> or <Link/>, since loginAndReturnUrl only works with regular <a> tags
      <a className={clsx("base-button base-hover-button bg-primary text-white", theme === "white" && "bg-white !text-charcoal hover:bg-white/90")} href={loginAndReturnUrl}>
              <span className="flex items-center justify-between gap-x-0.5 whitespace-nowrap">
                <Translation da="Log ind" de="Anmelden" en="Log in" no="Logg inn" sv="Logga in" />

                <OutlineChevronRightIcon className="h-2 w-2" />
              </span>
            </a>}
        </ToolbarMenuItem> : null}
    </ul>;
}