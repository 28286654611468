import { Link } from "@/components/Link/Link.component";
type MonitorBrandLinkProps = {
  readonly targetUrl: string | null;
};
export function MonitorBrandLink({
  targetUrl
}: MonitorBrandLinkProps) {
  const brandName = "Monitormedier";
  return targetUrl !== null ? <Link className="font-theme-bold hover:underline" href={targetUrl} rel="noreferrer" openInNewTab>
      {brandName}
    </Link> : <span className="font-bold">{brandName}</span>;
}